<template>
  <div class="pyodide" id="pyodide">
    <b-modal :active.sync="isShowingHelp" scroll="keep" :width="720">
      <div class="card">
        <div class="card-content" id="help">
          This is an in-browser Python 3 environment and editor. You can hide/show the console logs by pressing the
          "Console" button. Finally, you can run the code present in the first tab (named "script.py") by pressing
          the "Run" button.
          <br>
          <br>
          <strong>Note:</strong> The code is run in <i>your</i> browser on <i>your</i> hardware in a sandboxed
          environment, not a server!
          This is done through the use of the <a href="https://github.com/iodide-project/pyodide">Pyodide</a>
          library, a wasm-compiled CPython fork.
        </div>
      </div>
    </b-modal>

    <div>
      <div class="columns" id="tabRow">
        <div class="column is-left is-vcentered">
          <b-tabs v-model="editorTab" type="is-boxed">
            <b-tab-item :label="tab" v-for="tab in editorTabs" :key="tab"
                        :icon="tab.includes('.py') ? 'language-python' : 'file-document-outline'"
                        @click="isConsoleOpen=false">
            </b-tab-item>
          </b-tabs>
          <div id="description">
            {{ description }}
          </div>
        </div>
        <div class="column is-right is-narrow" id="buttons">
          <div class="columns is-vcentered is-centered">
            <div class="column">
              <b-button icon-left="console" size="is-medium" type="is-dark" @click="isConsoleOpen=!isConsoleOpen">
                Console
              </b-button>
            </div>
            <div class="column">
              <b-button icon-left="play" size="is-medium" type="is-success" @click="runCode()">
                Run
                <b-loading id="loading" :is-full-page="false" :active="isLoading" :can-cancel="false"></b-loading>
              </b-button>
            </div>
            <div class="column">
              <b-button icon-left="help-circle-outline" size="is-medium" @click="isShowingHelp=true">Help</b-button>
            </div>
          </div>
        </div>
      </div>
      <prism-editor :code="editorTab === 0 ? code : files[editorTabs[editorTab]]"
                    :language="currEditorTabName.endsWith('py') ? 'py' : 'md'"
                    :lineNumbers="false"
                    :readonly="currEditorTabName !== 'script.py'"
                    @change="onChange($event)">
      </prism-editor>
      <b-collapse :open="isConsoleOpen">
        <div class="box" id="console">
          <div class="content">
            <div id="consoleText" v-for="line in consoleText" :key="line" v-html="line">

            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import "prismjs";
import "@/assets/prefixed_prismjs.css"
import "@/assets/prefixed_prismjs_coy.css"
import PrismEditor from 'vue-prism-editor'
import PyodideWorker from 'comlink-loader!@/pyodide-worker'

export default {
  name: "Pyodide",
  components: {
    PrismEditor
  },
  created() {
    console.log("Loading pyodide...");
    (async () => {
      const worker = PyodideWorker()
      const workerInstance = await new worker.PyodideWorkerInstance()

      await workerInstance.install()

      let fileLoad = ""
      for (const path in this.$props.files) {
        fileLoad += 'vfs.add_file("' + path + '", """' + this.$props.files[path] + '""")\n'
      }

      await workerInstance.add_files(fileLoad)

      if (this.pyimport.length === 0) {
        this.isLoading = false
      } else {
        await workerInstance.load_packages(this.pyimport)
        this.isLoading = false
      }

      this.pyodide = workerInstance
    })().then()
  },
  computed: {
    currEditorTabName: function () {
      return this.editorTabs[this.editorTab]
    }
  },
  data: function () {
    let otherFiles = []
    for (const path in this.$props.files) {
      otherFiles.push(path)
    }

    return {
      isLoading: true,
      isConsoleOpen: false,
      isShowingHelp: false,
      editorTab: 0,
      editorTabs: ["script.py"].concat(otherFiles),
      consoleText: [],
      pyodide: null
    }
  },
  props: {
    files: {  // Use as an object e.g. <Pyodide :files="{'filepath': require('file')}"></Pyodide>
      default() {
        return {}
      }
    },
    description: {
      default: ""
    },
    code: {
      default: ""
    },
    pyimport: {  // Is an array e.g. <Pyodide :pyimport="['numpy']"></Pyodide>
      default() {
        return []
      }
    }
  },
  methods: {
    runCode() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      (async () => {
        this.consoleText = []
        let values = await this.pyodide.run_code(this.code)
        let success = values[0]
        let rawText = values[1]

        for (const line in rawText) {
          this.consoleText.push(rawText[line].replace(/\n/g, "<br>"))
        }

        await this.pyodide.reset_logs()

        this.endRun(success)
      })().then()
    },
    onChange(code) {
      if (this.editorTab === 0) {
        this.code = code
      }
    },
    endRun(success) {
      this.isConsoleOpen = true
      this.isLoading = false

      this.$buefy.notification.open({
        message: success ? "Successful run!" : "Uh oh, something went wrong!",
        type: success ? "is-success" : "is-danger",
        position: 'is-bottom-right',
        hasIcon: true,
        duration: 5000
      })
    }
  }
}
</script>

<style scoped>
#tabRow {
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-bottom: 0;
}

#console {
  margin-right: 1.25em;
  margin-left: 1.25em;
  margin-top: -1em;
  background-color: #313335;
}

#consoleText {
  margin-top: -0.5em;
  margin-bottom: -0.5em;
  color: white;
  text-align: left;
}

#description {
  margin-top: -2.5em;
  margin-bottom: -1em;
  text-align: left;
  padding-left: 15px;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
}

#help {
  text-align: justify;
  font-size: 18px;
}

#pyodide {
  font-size: 17px;
}
</style>
