<template>
  <AustinBackground>
    <div id="editor">
      <h1 class="austin-page-title"><u>In-Browser Python Editor</u></h1>
      <br>
      <Pyodide :files="{'test.py': require('@/assets/test.py').default, 'test.txt': require('@/assets/test.txt').default}"
               description="Test environment for the in-browser python editor"
               code="def main():
      print('Hello World')

  if __name__ == '__main__':
      main()">
      </Pyodide>
    </div>
  </AustinBackground>
</template>

<script>
import Pyodide from "@/components/Pyodide";
import AustinBackground from "./AustinBackground";

export default {
  name: "AustinFullEditor",
  components: {AustinBackground, Pyodide},
  metaInfo: {
    title: "Python Editor"
  },
}
</script>

<style scoped lang="scss">
@import "../common.scss";

.pyodide {
  padding: 1em 1em 1em 1em;
  background-color: white;
}
</style>
