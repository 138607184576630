<template>
  <div v-show="show" id="cookiebanner">
    <b-message type="is-info" has-icon>
      <div class="columns is-mobile is-vcentered">
        <div class="column is-three-quarters">
          This site uses cookies to enhance your experience!
        </div>
        <div class="column">
          <b-button type="is-success" @click="accept">OK</b-button>
        </div>
      </div>
    </b-message>
  </div>
</template>

<script>
export default {
  name: "AustinCookie",
  created() {
    if (!this.isAccepted()) {
      this.show = true
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    isAccepted() {
      let res = window.localStorage['austinv11CookieAccept']
      return !!res;
    },
    accept() {
      this.show = false
      window.localStorage.setItem('austinv11CookieAccept', 'true')
    }
  }
}
</script>

<style scoped>
#cookiebanner {
  position: fixed;
  z-index: 10;
  bottom: 2em;
  right: 2em;
}
</style>
