<template>
  <div class="austinNavbar">
    <b-navbar :fixed-top="true" id="navbar" wrapper-class="container">
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img
              id="brand"
              :src="require('@/assets/icons/Modern\ wht.png')"
              alt="Icon"
          >
          <div id="blogbrand">
            Austin's Blog
          </div>
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-item tag="router-link" :to="{ path: '/' }" id="home">
          Home
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/blog' }">
          Blog
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/about' }">
          About
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/projects' }">
          Projects
        </b-navbar-item>
        <b-navbar-dropdown label="Tools">
          <b-navbar-item tag="router-link" :to="{ path: '/editor' }">
            In-Browser Python Editor
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ path: '/sequence' }">
            In-Browser Sequence Viewer
          </b-navbar-item>
        </b-navbar-dropdown>
      </template>

      <template #end>
        <b-navbar-item href="https://github.com/austinv11">
          <b-icon icon="github" size="is-medium"></b-icon>
        </b-navbar-item>
        <b-navbar-item href="https://www.linkedin.com/in/austin-varela/">
          <b-icon icon="linkedin" size="is-medium"></b-icon>
        </b-navbar-item>
        <b-navbar-item
            href="mailto:avarela@u.rochester.edu?subject=[austinv11.com] Personal Inquiry&body=Please do not send unsolicited product advertisements!">
          <b-icon icon="email" size="is-medium"></b-icon>
        </b-navbar-item>
        <!-- TODO Make smaller and activate once the website is finished -->
<!--        <b-navbar-item href="https://discord.gg/qaAEZPaSCP">-->
<!--          <b-icon id="discordbrand" icon="discord" size="is-medium"></b-icon>-->
<!--        </b-navbar-item>-->
      </template>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "AustinNavbar"
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Caption&display=swap'); /*https://fonts.google.com/specimen/PT+Sans+Caption?preview.text=Computational+Biologist+programmer&preview.text_type=custom&sidebar.open&selection.family=PT+Sans+Caption#standard-styles*/

.austinNavbar {
  font-family: 'PT Sans Caption', sans-serif;
  margin-bottom: 3rem;
}

#brand {
  -webkit-transform: rotate(25deg) scale(2);
  -moz-transform: rotate(25deg) scale(2);
  -ms-transform: rotate(25deg) scale(2);
  -o-transform: rotate(25deg) scale(2);
  transform: rotate(25deg) scale(2);
}

#blogbrand {
  padding-left: 0.5em;
}
</style>
