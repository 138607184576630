<template>
  <div>
<!--  FIXME: Mobile screen shape distribution is screwed up, disabled for now  -->
    <div class="austin-background" v-if="!isMobileDensity($screen.width * $screen.height)">
      <!-- TODO: Experiment with animation options here https://github.com/Aminerman/vue-kinesis -->
      <KinesisContainer tag="svg" v-for="shape in shapes" :key="shape.id"
                        :stroke-width="shape.scale/15"
                        :height="shape.scale*4" :width="shape.scale*4"
                        :stroke="shape.color" :fill="!shape.filled ? 'transparent' : shape.color"
                        :active="!isMobileDensity($screen.width * $screen.height)"
                        :style="{ top: ($screen.height * shape.y) - shape.scale, left: ($screen.width * shape.x) - shape.scale, position: 'absolute' }">

          <KinesisElement v-if="shape.shapeType === 'circle'" tag="circle" :cx="shape.scale*2" :cy="shape.scale*2"
                          :r="shape.scale/3" type="scale"/>

          <KinesisElement v-else-if="shape.shapeType === 'square'" tag="rect"
                          :height="shape.scale/3" :width="shape.scale/3" :x="2*shape.scale - shape.scale/6" :y="2*shape.scale - shape.scale/6"
                          :transform="'rotate(' + shape.rotation + ')'"
                          type="scale"/>

        <KinesisElement v-else-if="shape.shapeType === 'triangle'" tag="polygon" :points="shape.polygonPoints()"
                        :transform="'rotate(' + shape.rotation + ')'" type="scale"/>

        <KinesisElement v-else-if="shape.shapeType === 'squiggle'" tag="path" :d="shape.polygonPoints()"
                        :transform="'rotate(' + shape.rotation + ')'" type="scale"/>
      </KinesisContainer>
    </div>
    <div class="slot-component">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {makeRandomShapeArray, isMobileDensity} from "../background-util.js";

export default {
  name: "AustinBackground",
  computed: {
      shapes() {
        return makeRandomShapeArray(this.$screen.width * this.$screen.height)
      }
  },
  data() {
    return {isMobileDensity}
  }
}
</script>

<style scoped lang="scss">
@import "../variables.scss";

  .austin-background {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    z-index: 0;  // FIXME: Invisible elements block this
    background-color: $subtle-bg-av11-color
  }

  .slot-component {
    position: relative;
    z-index: 1 !important;
  }
</style>
