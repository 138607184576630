<template>
  <div id="sidebar">
    <b-menu>
      <b-menu-list label="Posts">
        <b-menu-item v-for="year in years" :key="year" :active="activeYear === year" expanded>
          <template slot="label" slot-scope="props">
            {{ year }}
            <b-icon class="is-pulled-right" :icon="props.expanded ? 'menu-down' : 'menu-up'"></b-icon>
          </template>
          <b-menu-item v-for="post in postsByYear[year]" :key="post.slug" :active="activeSlug === post.slug"
                       @click="$router.push('/blog/' + post.slug)">
            <template slot="label">
              {{ post.title }}
            </template>
          </b-menu-item>
        </b-menu-item>
      </b-menu-list>
    </b-menu>
  </div>
</template>

<script>
import {getAllPosts} from "@/blog-manager";
import {getYear} from 'date-fns'

export default {
  name: "AustinBlogSidebar",
  data: function () {
    const allPosts = getAllPosts()
    let postsByYear = {}
    let years = []
    for (const post in allPosts) {
      const postObj = allPosts[post]
      const year = getYear(postObj.date)
      if (!(year in postsByYear)) {
        postsByYear[year] = []
        years.push(year)
      }
      postsByYear[year].push(postObj)
    }
    return {
      postsByYear: postsByYear,
      years: years,
      activeYear: years[0]
    }
  },
  props: {
    'activeSlug': {
      default() {
        return null
      }
    }
  }
}
</script>

<style scoped>
#sidebar {
  padding-right: 1em;
  padding-top: 3em;
}
</style>
