<template>
  <div class="seq-viewer">
    <div v-if="seqCollection === null && !loading" id="seqUpload">
      <b-field>
        <b-upload drag-drop @input="parseSequence" :rounded="true">
          <section class="section">
            <div class="content has-text-centered">
              <p>
                <b-icon icon="upload" size="is-large"></b-icon>
              </p>
              <p>Upload Sequence File (FASTA, CLUSTAL)</p>
              <p>File Size Limit: 5MB</p>
            </div>
          </section>
        </b-upload>
      </b-field>
    </div>
    <div v-else id="seqView">
      <section v-if="!loading" id="buttonRow">
        <div class="columns">
          <div class="column">
            {{ description }}
          </div>
          <div class="columns is-right">
            <div class="column">
              <b-dropdown aria-role="list">
                <template #trigger>
                  <b-button icon-left='palette-outline'>Color Scheme</b-button>
                </template>
                <b-dropdown-item aria-role="listitem" @click="this.noColorScheme">No Coloring</b-dropdown-item>
                <b-dropdown-item aria-role="listitem" @click="this.aliviewColorScheme">AliView (Default)</b-dropdown-item>
                <b-dropdown-item aria-role="listitem" @click="this.clustalColorScheme">Clustal (Adaptive)</b-dropdown-item>
              </b-dropdown>
            </div>

            <div class="column">
              <b-dropdown aria-role="list">
                <template #trigger>
                  <b-button icon-left='cloud-download-outline'>Download Sequences</b-button>
                </template>
                <b-dropdown-item aria-role="listitem" @click="this.downloadFasta">Download as FASTA (.fasta)</b-dropdown-item>
                <b-dropdown-item aria-role="listitem" @click="this.downloadClustal">Download as Clustal (.aln)</b-dropdown-item>
                <b-dropdown-item aria-role="listitem" @click="this.downloadPhylip">Download as PHYLIP (interlaced, .phy)</b-dropdown-item>
              </b-dropdown>
            </div>

            <div v-if="canClear" class="column">
              <b-button icon-left="close-circle" type="is-danger" @click="seqCollection=null">Clear</b-button>
            </div>
          </div>
        </div>
      </section>

      <splitpanes>
        <pane min-size="5" max-size="80" size="20" class="seqTitlesPane">
          <div v-if="loading">
            <b-skeleton width="50%"></b-skeleton>
            <b-skeleton width="30%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="60%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="50%"></b-skeleton>
            <b-skeleton width="60%"></b-skeleton>
            <b-skeleton width="45%"></b-skeleton>
          </div>
          <div v-else>
            <p class="recordLine" v-for="rec in this.seqCollection.records" :key="rec.title">
              {{ rec.title }}
            </p>
          </div>
        </pane>
        <pane class="seqContentPane">
          <div v-if="loading">
            <b-skeleton width="100%"></b-skeleton>
            <b-skeleton width="100%"></b-skeleton>
            <b-skeleton width="100%"></b-skeleton>
            <b-skeleton width="100%"></b-skeleton>
            <b-skeleton width="100%"></b-skeleton>
            <b-skeleton width="100%"></b-skeleton>
            <b-skeleton width="100%"></b-skeleton>
            <b-skeleton width="100%"></b-skeleton>
          </div>
          <div v-else>
<!--            <p>-->  <!-- TODO: Implement scale to get positions -->
<!--              <svg v-for="i in this.seqCollection.recordLength-1" style="height: 1em; width: 1em;" viewBox="0 0 100 100" :key="i">-->
<!--                <text v-if="i % 10 === 0" x="40" y="25"> {{ i }} </text>-->
<!--                <line v-if="i % 5 === 0" x1="50" y1="100" x2="50" y2="50" stroke="black"></line>-->
<!--                <line v-else x1="50" y1="100" x2="50" y2="75" stroke="black"></line>-->
<!--              </svg>-->
<!--            </p>-->
            <p class="recordLine" v-for="rec in this.seqCollection.records" :key="rec.title">
            <span class="seqLetterSpan" v-for="i in rec.sequence.length"
                  :key="i"
                  :style="colorScheme.colorPosition(seqCollection, rec.sequence[i-1], aas, i-1).toSyleCss()">{{rec.sequence[i-1]}}</span>
            </p>
          </div>
        </pane>
      </splitpanes>
    </div>
  </div>
</template>

<script>
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import { parseSequences, NO_COLOR_SCHEME, CLUSTAL_ADAPTIVE_COLOR_SCHEME, ALIVIEW_COLOR_SCHEME  } from '@/bio'
import { download } from '@/utils'

export default {
  name: "SequenceViewer",
  components: {
    Splitpanes,
    Pane
  },
  methods: {
    downloadFasta() {
      download('sequence.fasta', this.seqCollection.toFasta())
    },
    downloadClustal() {
      download('sequence.aln', this.seqCollection.toClustal())
    },
    downloadPhylip() {
      download("sequence.phy", this.seqCollection.toPhylip())
    },
    noColorScheme() {
      this.colorScheme = NO_COLOR_SCHEME
    },
    clustalColorScheme() {
      this.colorScheme = CLUSTAL_ADAPTIVE_COLOR_SCHEME
    },
    aliviewColorScheme() {
      this.colorScheme = ALIVIEW_COLOR_SCHEME
    },
    parseSequence(file) {
      if (file.size > 5 * 1000 * 1000) {
        this.$buefy.notification.open({
          message: "File too large (>5MB)!",
          type: "is-danger",
          position: 'is-bottom-right',
          hasIcon: true,
          duration: 5000
        })
        return
      }

      let seqType = file.name.split(".").pop();
      this.loading = true;

      (async () => {
        try {
          let sequence = await file.text()
          this.seqCollection = parseSequences(sequence, seqType)
          if (this.seqCollection === null || this.seqCollection.length() === 0) {
            throw new Error()
          }
        } catch (e) {
          console.log(e)
          this.$buefy.notification.open({
            message: "Unable to parse sequence file!",
            type: "is-danger",
            position: 'is-bottom-right',
            hasIcon: true,
            duration: 5000
          })
        }
        this.loading = false
      })().then()
    }
  },
  props: {
    sequence: {
      default: null
    },
    seqType: {
      default: null
    },
    isAminoAcids: {
      default: null
    },
    scheme: {
      default: 'aliview'
    },
    canClear: {
      default: true
    },
    description: {
      default: ""
    }
  },
  created() {
    if (this.sequence !== null) {
      this.loading = true
      this.seqCollection = parseSequences(this.sequence, this.seqType, this.isAminoAcids)
      this.aas = this.seqCollection.isAminoAcidCollection()
      this.loading = false
    }
    switch (this.scheme) {
      case 'clustal':
        this.colorScheme = CLUSTAL_ADAPTIVE_COLOR_SCHEME
        break
      case "aliview":
        this.colorScheme = ALIVIEW_COLOR_SCHEME
        break
      default:
        this.colorScheme = NO_COLOR_SCHEME
    }
  },
  data: function() {
    return {
      seqCollection: null,
      aas: true,
      colorScheme: null,
      loading: false
    }
  }
}
</script>

<style scoped>
.seqTitlesPane {
  background-color: white;
  overflow-x: scroll;
  padding-left: 1rem;
}
.seqContentPane {
  background-color: white;
  overflow-x: scroll;
  padding-left: 1rem;
  padding-right: 1rem;
}
#seqView >>> .splitpanes__splitter {
  min-width: 6px;
  background: lightgray;
}
.recordLine {
  text-align: left;
  white-space: nowrap;
  font-family: monospace, monospace;
  line-height: 1.15;
}
.seqLetterSpan {
  padding-left: 0.2em;
  padding-right: 0.2em;
  font-family: monospace, monospace;
}
#buttonRow {
  padding-bottom: 1em;
}
</style>
