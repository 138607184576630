<template>
  <div id="app">
    <div id="wrapper">
      <AustinCookie></AustinCookie>
      <AustinNavbar></AustinNavbar>
      <router-view></router-view>
    </div>
    <AustinFooter></AustinFooter>
  </div>
</template>

<script>
import AustinNavbar from "@/components/AustinNavbar";
import AustinFooter from "@/components/AustinFooter";
import AustinCookie from "@/components/AustinCookie";

export default {
  name: 'App',
  components: {
    AustinCookie,
    AustinFooter,
    AustinNavbar,
  },
  metaInfo: {
    titleTemplate: '%s | Austin\'s Blog',
    meta: [  // TODO: OEmbed support
      {
        property: 'og:type',
        content: 'website'
      },
      {
        property: 'og:title',
        content: "Austin's Blog"
      },
      {
        property: 'og:image',
        content: require('./assets/icons/Modern wht.png')
      },
      {
        property: 'og:image:alt',
        content: 'Profile Picture'
      },
      {
        property: 'og:site_name',
        content: "Austin's Blog"
      },
      {
        property: 'og:locale',
        content: 'en_US'
      },
      {
        property: 'article:author',
        content: 'Austin Varela'
      }
    ]
  }
}
</script>

<style lang="scss">
/*.card-header-icon {*/
/*  color: #5d44a4;*/
/*}*/
/*.menu-list a.is-active {*/
/*  background-color: #5d44a4;*/
/*}*/
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#wrapper {
  flex: 1;
}
</style>
