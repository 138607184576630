<template>
  <AustinBackground>
    <div class="blog-post">
      <div class="columns is-mobile is-centered">
        <div class="column" id="paddingcolumn">
        </div>
        <div class="column" id="maincolumn">
          <section class="section">
            <div id="content">
              <div class="card">
                <header class="card-header">
                  <p class="card-header-title is-vcentered"> {{ title }} </p>
                  <a class="card-header-icon">
                    <b-icon icon="book-open-variant" size="is-medium"></b-icon>
                  </a>
                </header>
                <div class="card-content is-left" id="postcontent">
                  <p id="postinfo">
                    By {{ author }} - {{ formattedDate }}
                  </p>
                  <br>
                  <b-message type="is-warning" has-icon class="is-vcentered">
                      Articles containing code examples assume a basic understanding of Python 3 syntax.
                  </b-message>
                  <slot></slot>
                </div>
                <div class="card-footer">
                  <div class="tag-br">
                    <a v-for="tag in tags" :key="tag"> #{{ tag }} </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="section" id="sharebuttons">
            <TwitterButton btnText="Tweet This" :url="currUrl" :description="title"></TwitterButton>
            <FacebookButton btnText="Share on Facebook" :url="currUrl" :description="title"></FacebookButton>
            <LinkedInButton btnText="Share on LinkedIn" :url="currUrl" :description="title"></LinkedInButton>
            <RedditButton btnText="Post on Reddit" :url="currUrl" :title="title"></RedditButton>
            <PocketButton btnText="Save on Pocket" :url="currUrl"></PocketButton>
            <EmailButton btnText="Send as Email" :url="currUrl"></EmailButton>
          </section>

          <section class="section" id="comments">
            <Disqus></Disqus>
          </section>
        </div>
        <div class="column" id="postscolumn">
          <AustinBlogSidebar :activeSlug="slug"></AustinBlogSidebar>
          <AustinAd>
            <Adsense
                data-ad-client="ca-pub-9440645214384284"
                data-ad-slot="3672203503">
            </Adsense>
          </AustinAd>
        </div>
      </div>
    </div>
  </AustinBackground>
</template>

<script>
import AustinBlogSidebar from "@/components/AustinBlogSidebar";
import AustinAd from "@/components/AustinAd"
import {formatRelative} from 'date-fns';
import TwitterButton from "vue-share-buttons/src/components/TwitterButton"
import FacebookButton from "vue-share-buttons/src/components/FacebookButton"
import LinkedInButton from "vue-share-buttons/src/components/LinkedInButton"
import RedditButton from "vue-share-buttons/src/components/RedditButton"
import PocketButton from "vue-share-buttons/src/components/PocketButton"
import EmailButton from "vue-share-buttons/src/components/EmailButton"
import AustinBackground from "./AustinBackground";

export default {
  name: "AustinBlogPost",
  components: {
    AustinBackground,
    AustinBlogSidebar,
    TwitterButton,
    FacebookButton,
    LinkedInButton,
    RedditButton,
    PocketButton,
    EmailButton,
    AustinAd
  },
  metaInfo() {
    return {
      // script: [{  TODO: Use on articles
      //     type: 'application/ld+json',
      //     json: {
      //         '@context': 'https://schema.org',
      //         '@type': 'Article',
      //         headline: this.metaInfo.title,
      //         alternativeTitle: 'Learn about me and Computational Biology!',
      //         image: require('../assets/av_profile_pic.jpeg'),  // TODO: Use favicon
      //         author: "Austin Varela",
      //         genre: "computational biology and bioinformatics",
      //         publisher: "Austin Varela",
      //         url: "https://austinv11.com",
      //         mainEntityOfPage: {
      //             "@type": "WebPage",
      //             "@id": "https://google.com/article"
      //         },
      //         "datePublished": "2020-06-20",
      //         "dateCreated": "2020-06-20",
      //         "dateModified": "2020-06-20",
      //         "description": "We love to do stuff to help people and stuff",
      //         "articleBody": "You can paste your entire post in here, and yes it can get really really long."
      //     }
      // }],
      title: this.title,
      meta: [
        {
          property: 'og:url',
          content: 'https://austinv11.com/blog/' + this.slug
        },
        {
          property: 'og:description',
          content: "The homepage for Austin's website."
        },
      ]
    }
  },
  computed: {
    formattedDate: function () {
      let dateStr = formatRelative(this.date, new Date())

      if (this.editDate !== null) {
        dateStr += " (Last Edited: " + formatRelative(this.editDate, new Date()) + ")"
      }

      return dateStr
    },
    currUrl: function () {
      return window.location.origin + this.$route.fullPath
    }
  },
  props: {
    'slug': {
      default() {
        return null
      }
    },
    'title': String,
    'author': String,
    'date': Date,
    'editDate': {
      default() {
        return null
      }
    },
    'tags': Array
  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&display=swap');
/*https://fonts.google.com/specimen/Noto+Sans+JP?sidebar.open&selection.family=Noto+Sans+JP:wght@700*/
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); /*https://fonts.google.com/specimen/Roboto?sidebar.open&selection.family=Roboto#standard-styles*/
@import "../common.scss";

.card-header-title {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 28px;
}

.card {
  font-family: 'Roboto', sans-serif;
}

.card-content:not(.pyodide) {
  font-size: 18px;
}

.pyodide {
  padding-top: 2em;
  padding-bottom: 2em;
}

.card-footer {
  font-size: 18px;
  padding: 0.5em 0.5em;
}

#postcontent {
  text-align: left;
}

#postinfo {
  color: dimgray;
  font-size: 16px;
}

#sharebuttons {
  margin-top: -3em;
  margin-bottom: -3em;
}

#paddingcolumn {
  flex: none;
  width: 6em;
}

#maincolumn {
  flex: none;
  width: 75%;
}

#postscolumn {
  flex: none;
}
</style>
