<template>
    <AustinBlogPost slug="welcome-to-austinv11-com" title="Welcome to austinv11.com" author="Austin Varela" :date="parseDate('2020-08-14T05:30')" :edit-date="parseDate(null)" :tags="['meta']">
        <div class="content">
          <h1 id="welcome-to-my-blog">Welcome to my blog!</h1>
<p>Originally, I built this site to act as a virtual showcase of my skills. However, both to test my knowledge and to contribute to the community I decided to also do some blogging. Specifically, I will be blogging about the more technical aspects of performing bioinformatics and computational biology research. These posts will also contain interactive and programming components.</p>
<p>All code on the site is <a href="https://python.org/">Python 3</a> and I assume basic knowledge of its syntax. Additionally, code snippets are embedded directly onto blog posts via a custom Python editor (you can try it out <a href="https://austinv11.com/editor">here</a>). This editor is capable of running Python 3 code in your browser through the use of the <a href="https://github.com/iodide-project/pyodide">Pyodide Project</a>.</p>
<p>My posts aim to get into the implementation details which are often obscured through web interfaces to allow for a greater understanding of the how and why things are done the way they are.</p>
<p>Here are some examples of topics I am planning to cover:</p>
<ul>
<li>Common file formats likely to be encountered (<code>FASTA</code>, <code>Newick</code>, etc.)</li>
<li>Landmark algorithms (<code>Smith-Waterman</code>, <code>Needleman–Wunsch</code>, etc.)</li>
<li>Important tools (<code>MAFFT</code>, <code>RAxML</code>, etc.)</li>
<li>Types of data analysis (<code>Network Analysis</code>, <code>Statistical Testing</code>, etc.)</li>
<li>Programming advice (<code>Best Practices</code>, <code>Useful Libraries/Modules</code>, etc.)</li>
</ul>
<p>If you have any questions or comments, let me know in the comment section below!</p>

        </div>
    </AustinBlogPost>
</template>

<script>
    import AustinBlogPost from "@/components/AustinBlogPost";
    import Pyodide from "@/components/Pyodide";
    import SequenceViewer from "@/components/SequenceViewer";
    import {parseISO} from 'date-fns';

    export default {
        name: "welcometoaustinv11com",
        // eslint-disable-next-line vue/no-unused-components
        components: {AustinBlogPost, Pyodide, SequenceViewer},
        methods: {
            parseDate(string) {
                if (string === null)
                    return null
                else
                    return parseISO(string)
            }
        }
    }
</script>

<style scoped>

</style>
