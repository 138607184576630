<template>
  <AustinBackground>
    <div id="viewer">
      <h1 class="austin-page-title"><u>In-Browser Sequence Viewer</u></h1>
      <br>
      <SequenceViewer description="A very simple read-only alignment viewer."></SequenceViewer>
    </div>
  </AustinBackground>
</template>

<script>
import SequenceViewer from "@/components/SequenceViewer";
import AustinBackground from "./AustinBackground";

export default {
  name: "AustinFullSequenceViewer",
  components: {AustinBackground, SequenceViewer},
  metaInfo: {
    title: "Alignment Viewer"
  },
}
</script>

<style scoped lang="scss">
@import "../common.scss";

.seq-viewer {
  padding: 1em 1em 1em 1em;
  background-color: white;
}
</style>
