<template>
  <div class="austinFooter">
    <footer class="footer">
      <div class="content has-text-centered">
        <p>
          Made with
          <b-icon icon="heart" size="is-small"></b-icon>
          by Austin Varela (me!) using
          <a href="https://vuejs.org/">Vue.js</a>, <a href="https://buefy.org/">Buefy</a>, and
          <a href="https://materialdesignicons.com/">Material Design Icons</a>.
        </p>
        <p>
          I am by no means a frontend developer, so please contact me if there are any problems with the site!
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "AustinFooter"
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Caption&display=swap'); /*https://fonts.google.com/specimen/PT+Sans+Caption?preview.text=Computational+Biologist+programmer&preview.text_type=custom&sidebar.open&selection.family=PT+Sans+Caption#standard-styles*/

.austinFooter {
  font-family: 'PT Sans Caption', sans-serif;
  z-index: 100;
}
</style>
