import { compareDesc, formatRelative, parseISO } from 'date-fns'


export class PostInfo {

    constructor(slug, title, summary, author, date, editDate, tags) {
        this.slug = slug
        this.title = title
        this.summary = summary
        this.author = author
        this.date = date
        this.editDate = editDate
        this.tags = tags
    }

    formattedDate() {
        return formatRelative(this.date, new Date())
    }

    formattedEditDate() {
        if (this.editDate === null) {
            return null
        }
        return formatRelative(this.editDate, new Date())
    }
}

function parseRawPost(slug, rawPost) {
    console.log(parseISO(rawPost.date))
    return new PostInfo(slug, rawPost.title, rawPost.summary, rawPost.author, parseISO(rawPost.date),
        rawPost.editDate === null ? null : parseISO(rawPost.editDate), rawPost.tags === null ? [] : rawPost.tags)
}

export function getAllPosts() {
    const rawPostData = require('@/generated/post-data.json')
    let postData = []
    for (const postSlug in rawPostData) {
        const post = rawPostData[postSlug]
        postData.push(parseRawPost(postSlug, post))
    }
    postData.sort((el1, el2) => { return compareDesc(el1.date, el2.date) })

    return postData
}

export function getPostForSlug(slug) {
    const rawPostData = require('@/generated/post-data.json')
    if (!(slug in rawPostData)) {
        return null
    }
    return parseRawPost(slug, rawPostData[slug])
}
