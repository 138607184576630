<template>
  <AustinBackground>
    <div id="blog">
      <h1 class="austin-page-title"><u>My Blog</u></h1>

      <div class="columns is-mobile is-centered">
        <div class="column">
        </div>
        <div class="column is-three-quarters">
          <section class="section" id="taglist">
            <b-taglist>
              <b-tag v-for="tag in allTags" :key="tag" :rounded="true"
                     :type="filter.includes(tag) ? 'is-info' : 'is-light'"
                     @close="toggleTag(tag)" closable size="is-medium">
                {{ tag }}
              </b-tag>
              <b-tag :type="filter.length === allTags.length ? 'is-info' : 'is-light'"
                     @close="toggleAll()" :rounded="true" closable size="is-medium">
                all
              </b-tag>
            </b-taglist>
          </section>
          <section class="section" v-for="post in posts" :key="post.slug">
            <div class="card" v-if="isPostShowing(post)">
              <a class="postAnchor" @click="onPostClick(post.slug)">
                <div class="card-header">
                  <p class="card-header-title is-2 is-left">
                    {{ post.title }}
                  </p>
                </div>
                <div class="card-content">
                  <div class="content">
                    <VueMarkdown>
                      {{ post.summary }}
                    </VueMarkdown>
                  </div>
                </div>
              </a>
              <div class="is-left">
                <a class="tagAnchor" v-for="tag in post.tags" :key="tag" @click="onTagClick(tag)"> #{{ tag }} </a>
              </div>
              <footer class="card-footer">
                <div class="card-footer-item">{{ post.author }}</div>
                <div class="card-footer-item">{{ post.formattedDate() }}</div>
              </footer>
            </div>
          </section>
        </div>
        <div class="column">
          <AustinBlogSidebar></AustinBlogSidebar>
        </div>
      </div>
    </div>
  </AustinBackground>
</template>

<script>
import {getAllPosts} from '@/blog-manager'
import AustinBlogSidebar from "@/components/AustinBlogSidebar";
import VueMarkdown from "vue-markdown";
import AustinBackground from "./AustinBackground";

export default {
  name: "AustinBlog",
  components: {AustinBackground, AustinBlogSidebar, VueMarkdown},
  metaInfo: {
    title: "Austin's Blog",
  },
  data: function () {
    const allPosts = getAllPosts()
    const allTags = this.getAllTags(allPosts)
    return {
      posts: allPosts,
      allTags: allTags,
      filter: allTags
    }
  },
  methods: {
    onTagClick(tag) {
      this.filter = [tag]
    },
    onPostClick(slug) {
      this.$router.push('/blog/' + slug)
    },
    isPostShowing(post) {
      for (const filterTag in this.filter) {
        if (post.tags.includes(this.filter[filterTag])) {
          return true
        }
      }

      return false
    },
    getAllTags(posts) {
      let allTags = []
      for (const post in posts) {
        for (const tag in posts[post].tags) {
          if (!(posts[post].tags[tag] in allTags)) {
            allTags.push(posts[post].tags[tag])
          }
        }
      }
      return allTags
    },
    toggleTag(tag) {
      if (this.filter.includes(tag)) {
        this.filter = this.filter.filter(item => item !== tag)
      } else {
        this.filter.push(tag)
      }
    },
    toggleAll() {
      if (this.filter.length === this.allTags.length)
        this.filter = []
      else
        this.filter = [...this.allTags]
    }
  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&display=swap');
/*https://fonts.google.com/specimen/Noto+Sans+JP?sidebar.open&selection.family=Noto+Sans+JP:wght@700*/
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); /*https://fonts.google.com/specimen/Roboto?sidebar.open&selection.family=Roboto#standard-styles*/
@import "../common.scss";
// Required for size mixins
@import "~bulma/sass/utilities/_all";
@import "~bulma/sass/utilities/mixins.sass";

.card-header-title {
  font-size: 22px;
  @include from($desktop) {
    font-size: 30px;
  }
}

.card {
  font-family: 'Roboto', sans-serif;
}

.content {
  text-align: justify;
  font-size: 20px;
}

.card-footer {
  font-size: 17px;
}

.postAnchor:hover {
  background-color: transparent !important;
}

.postAnchor {
  color: unset;
}

.tagAnchor {
  font-size: 20px;
}

#taglist {
  margin-bottom: -5em;
}
</style>
