
export function download(file, text) {
    let element = document.createElement('a')

    element.setAttribute('href', toDataUri(text))
    element.setAttribute('download', file)

    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
}

export function toDataUri(fileText) {
    return "data:text/plain;base64," + btoa(fileText)
}
