import Vue from 'vue'
import VueRouter from 'vue-router'
import VueDisqus from 'vue-disqus'
import VueMeta from 'vue-meta'
import App from './App.vue'
import AustinHome from "@/components/AustinHome";
import '@mdi/font/css/materialdesignicons.css'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import "./variables.scss"
import AustinAbout from "@/components/AustinAbout";
import AustinBlog from "@/components/AustinBlog";
import AustinProjects from "@/components/AustinProjects";
import AustinFullEditor from "@/components/AustinFullEditor";
import Prism from "prismjs"  // Prism is used for the editor
import "prismjs/plugins/custom-class/prism-custom-class.js";
import {getInjections} from "@/generated/components"
import VueGtag from "vue-gtag";
import Ads from 'vue-google-adsense';
import VueKinesis from 'vue-kinesis';
import VueScreen from 'vue-screen';
import AustinFullSequenceViewer from "./components/AustinFullSequenceViewer";

Vue.config.productionTip = false

Prism.plugins.customClass.prefix("prism-")

Vue.use(Buefy, {
  // // Config options for enabling font-awesome support
  // defaultIconComponent: 'vue-fontawesome',
  // defaultIconPack: 'fas',
  defaultContainerElement: '#content',
  // Custom options
  //...
})

Vue.use(VueDisqus, {
  shortname: 'austinv11'
})

const routes = [
  { path: "/", component: AustinHome },
  { path: "/about", component: AustinAbout },
  { path: "/blog", component: AustinBlog },
  { path: "/projects", component: AustinProjects },
  { path: "/editor", component: AustinFullEditor },
  { path: "/sequence", component: AustinFullSequenceViewer }
]

const injections = getInjections()
for (const inject in injections) {
  const injectObj = injections[inject]
  routes.push({ path: injectObj.path, component: injectObj.component })
}

Vue.use(VueRouter)
Vue.use(VueMeta)

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history'
})

Vue.use(VueGtag, {
  config: { id: "UA-52146833-1" }
}, router);

Vue.use(require('vue-script2'))

Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)

Vue.use(VueKinesis)
Vue.use(VueScreen, 'bulma')

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
