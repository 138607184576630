<template>
  <AustinBackground>
    <div id="about">
      <!-- Header -->
      <div class="columns is-vcentered is-centered" id="title">
        <div class="column is-narrow">
          <img src="../assets/av_profile_pic.jpeg" alt="Profile Picture" class="rounded">
        </div>
        <div class="column is-narrow">
          <h1 class="title austin-page-title"><u>About Me</u></h1>
        </div>
      </div>

      <!-- Intro Card -->
      <section class="section">
        <div class="columns is-mobile is-centered">
          <div class="column is-three-quarters">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  I'm Austin.
                </p>
              </header>
              <div class="card-content">
                I am currently working as a Computational Biology researcher at the University of Rochester
                Department of Biology in Dr. John (Jack) Werren's Lab. I am passionate about all things
                related to biology and technology, which has led to me learn about a wide variety of topics.
                In particular, I have knowledge in the realms of: Computational Biology, Bioinformatics,
                Software Engineering, Data Science, Evolutionary Biology, and Genetics. I feel like I have
                a unique perspective on topics such as education as I am (originally) a self-taught
                programmer and I am both a first-generation American and first-generation college graduate.
                I love meeting new people, so if you feel like chatting feel free to
                <a href="mailto:avarela@u.rochester.edu?subject=[austinv11.com]&body=Please do not send me unsolicited advertisements!">email
                  me</a>!
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Education Header -->
      <section class="hero is-primary">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">
              Education
            </h1>
            <h2 class="subtitle">
              I've learned some things...
            </h2>
          </div>
        </div>
      </section>

      <!-- Education Cards -->
      <section class="section">
        <div class="columns is-mobile is-centered">
          <div class="column is-three-quarters">
            <div class="card">
              <header class="card-header">
                <div class="media">
                  <div class="columns is-vcentered is-centered">
                    <div class="column is-narrow">
                      <div class="media-left">
                        <figure class="image is-96x96">
                          <img :src="require('../assets/Weill_Cornell_Graduate_School_of_Medical_Sciences.png')"
                               alt="Weill Cornell">
                        </figure>
                      </div>
                    </div>
                    <div class="column">
                      <div class="media-content is-vcentered">
                        <p class="card-header-title">
                          Cornell University: Weill Cornell Graduate School of Medical Sciences
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div class="card-content">
                <nav class="level">
                  <div class="has-text-left">
                    <p>Master of Science: Computational Biology</p>
                  </div>
                  <div class="has-text-right">
                    <p>Intended: 2021-2023</p>
                  </div>
                </nav>
              </div>
            </div>

            <br>

            <div class="card">
              <header class="card-header">
                <div class="media">
                  <div class="columns is-vcentered is-centered">
                    <div class="column is-narrow">
                      <div class="media-left">
                        <figure class="image is-96x96">
                          <img :src="require('../assets/UR.png')" alt="U of R">
                        </figure>
                      </div>
                    </div>
                    <div class="column">
                      <div class="media-content is-vcentered">
                        <p class="card-header-title">
                          University of Rochester: School of Arts & Sciences
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div class="card-content">
                <nav class="level">
                  <div class="has-text-left">
                    <p>Bachelor of Science: Computational Biology</p>
                  </div>
                  <div class="has-text-right">
                    <p>2016-2020</p>
                  </div>
                </nav>
                <div class="content card-list">
                  <ul>
                    <li>Honor's In Research Distinction</li>
                    <li>Honor's Thesis Title: <i>Evolutionary Rate Correlations: A Tool to Predict Protein-Protein
                      Interactions.</i></li>
                  </ul>
                </div>
                <nav class="level">
                  <div class="has-text-left">
                    <p>Bachelor of Arts: Data Science</p>
                  </div>
                  <div class="has-text-right">
                    <p>2016-2020</p>
                  </div>
                </nav>
                <div class="content card-list">
                  <ul>
                    <li>Graduated with Distinction</li>
                  </ul>
                </div>
              </div>
            </div>

            <br>

            <div class="card">
              <header class="card-header">
                <div class="media">
                  <div class="columns is-vcentered is-centered">
                    <div class="column is-narrow">
                      <div class="media-left">
                        <figure class="image is-96x96">
                          <img :src="require('../assets/erhsnyc.png')" alt="ERHS">
                        </figure>
                      </div>
                    </div>
                    <div class="column">
                      <div class="media-content is-vcentered">
                        <p class="card-header-title">
                          Eleanor Roosevelt High School
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div class="card-content">
                <nav class="level">
                  <div class="has-text-left">
                    <p>Advanced Regents Diploma with Honors Distinction</p>
                  </div>
                  <div class="has-text-right">
                    <p>2012-2016</p>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Award Header -->
      <section class="hero is-success">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">
              Honors & Awards
            </h1>
            <h2 class="subtitle">
              I've won some things...
            </h2>
          </div>
        </div>
      </section>

      <!-- Awards -->
      <!-- Education Cards -->
      <section class="section">
        <div class="columns is-mobile is-centered">
          <div class="column is-three-quarters">
            <div class="card">
              <header class="card-header">
                <div class="media">
                  <div class="columns is-vcentered is-centered">
                    <div class="column is-narrow">
                      <div class="media-left">
                        <figure class="image is-96x96">
                          <img :src="require('../assets/UR.png')" alt="U of R">
                        </figure>
                      </div>
                    </div>
                    <div class="column">
                      <div class="media-content is-vcentered">
                        <p class="card-header-title">
                          Grace McCormack Fund for Biology Prize
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div class="card-content">
                <nav class="level">
                  <div class="has-text-left">
                    <p>Awarded for exhibiting excellence in science and for service to the University of Rochester biology
                      department.</p>
                  </div>
                  <div class="has-text-right">
                    <p>2020</p>
                  </div>
                </nav>
              </div>
            </div>

            <br>

            <div class="card">
              <header class="card-header">
                <div class="media">
                  <div class="columns is-vcentered is-centered">
                    <div class="column is-narrow">
                      <div class="media-left">
                        <figure class="image is-96x96">
                          <img :src="require('../assets/UR.png')" alt="U of R">
                        </figure>
                      </div>
                    </div>
                    <div class="column">
                      <div class="media-content is-vcentered">
                        <p class="card-header-title">
                          de Kiewiet Summer Research Fellowship
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div class="card-content">
                <nav class="level">
                  <div class="has-text-left">
                    <p>Summer research fellowship that culminated in a poster presentation.</p>
                  </div>
                  <div class="has-text-right">
                    <p>2019</p>
                  </div>
                </nav>
              </div>
            </div>

            <br>

            <div class="card">
              <header class="card-header">
                <div class="media">
                  <div class="columns is-vcentered is-centered">
                    <div class="column is-narrow">
                      <div class="media-left">
                        <figure class="image is-96x96">
                          <img :src="require('../assets/UR.png')" alt="U of R">
                        </figure>
                      </div>
                    </div>
                    <div class="column">
                      <div class="media-content is-vcentered">
                        <p class="card-header-title">
                          Dean's List
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div class="card-content">
                <nav class="level">
                  <div class="has-text-left">
                    <p>Dean’s List honors were not awarded in Spring 2020 due to the COVID-19 pandemic.</p>
                  </div>
                  <div class="has-text-right">
                    <p>Spring 2017- Fall 2019</p>
                  </div>
                </nav>
              </div>
            </div>

            <br>

            <div class="card">
              <header class="card-header">
                <div class="media">
                  <div class="columns is-vcentered is-centered">
                    <div class="column is-narrow">
                      <div class="media-left">
                        <figure class="image is-96x96">
                          <img :src="require('../assets/UR.png')" alt="U of R">
                        </figure>
                      </div>
                    </div>
                    <div class="column">
                      <div class="media-content is-vcentered">
                        <p class="card-header-title">
                          MLC Book Award
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div class="card-content">
                <nav class="level">
                  <div class="has-text-left">
                    <p>Awarded for excellence in foreign language scholarship (Portuguese).</p>
                  </div>
                  <div class="has-text-right">
                    <p>2019</p>
                  </div>
                </nav>
              </div>
            </div>

            <br>

            <div class="card">
              <header class="card-header">
                <div class="media">
                  <div class="columns is-vcentered is-centered">
                    <div class="column is-narrow">
                      <div class="media-left">
                        <figure class="image is-96x96">
                          <img :src="require('../assets/UR.png')" alt="U of R">
                        </figure>
                      </div>
                    </div>
                    <div class="column">
                      <div class="media-content is-vcentered">
                        <p class="card-header-title">
                          Prince Street Scholarship
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div class="card-content">
                <nav class="level">
                  <div class="has-text-left">
                    <p>Awarded to high academic achievers that possess outstanding creative capacity in the arts.</p>
                  </div>
                  <div class="has-text-right">
                    <p>2016-2020</p>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Certifications Header -->
      <section class="hero is-warning">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">
              Certifications
            </h1>
            <h2 class="subtitle">
              I'm certified to be a cool dude...
            </h2>
          </div>
        </div>
      </section>

      <!-- Certifications -->
      <section class="section">
        <div class="columns is-mobile is-centered">
          <div class="column is-three-quarters">
            <div class="card">
              <header class="card-header">
                <div class="media">
                  <div class="columns is-vcentered is-centered">
                    <div class="column is-narrow">
                      <div class="media-left">
                        <figure class="image is-96x96">
                          <img :src="require('../assets/GeneralAssembly.png')" alt="GA">
                        </figure>
                      </div>
                    </div>
                    <div class="column">
                      <div class="media-content is-vcentered">
                        <p class="card-header-title">
                          General Assembly
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div class="card-content">
                <nav class="level">
                  <div class="has-text-left">
                    <p>Certified Data Scientist Level 1 Credential</p>
                  </div>
                  <div class="has-text-right">
                    <p>2019 (No Expiration)</p>
                  </div>
                </nav>
                <p>
                  <a href="https://my.generalassemb.ly/awards/O7EUVIFI7">License #O7EUVIFI7</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Affiliations Header -->
      <section class="hero is-danger">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">
              Professional Affiliations
            </h1>
            <h2 class="subtitle">
              People affiliate with me...
            </h2>
          </div>
        </div>
      </section>

      <!-- Affiliations -->
      <section class="section">
        <div class="columns is-mobile is-centered">
          <div class="column is-three-quarters">
            <div class="card">
              <header class="card-header">
                <div class="media">
                  <div class="columns is-vcentered is-centered">
                    <div class="column is-narrow">
                      <div class="media-left">
                        <figure class="image is-96x96">
                          <img :src="require('../assets/Iscb_logo.png')" alt="ISCB" id="iscblogo">
                        </figure>
                      </div>
                    </div>
                    <div class="column">
                      <div class="media-content is-vcentered">
                        <p class="card-header-title">
                          International Society for Computational Biology
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div class="card-content">
                <nav class="level">
                  <div class="has-text-left">
                    <p>Regular Membership</p>
                  </div>
                  <div class="has-text-right">
                    <p>2019-Present</p>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Ending Header -->
      <section class="hero is-dark is-medium">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">
              For a full Curriculum Vitae or Résumé please contact me.
            </h1>
            <h2 class="subtitle">
              That's all folks!
            </h2>
          </div>
        </div>
      </section>
    </div>
  </AustinBackground>
</template>

<script>
import AustinBackground from "./AustinBackground";
export default {
  name: "AustinAbout",
  components: {AustinBackground},
  metaInfo: {
    title: "About Me"
  },
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); /*https://fonts.google.com/specimen/Roboto?sidebar.open&selection.family=Roboto#standard-styles*/
@import "../common.scss";

// Required for size mixins
@import "~bulma/sass/utilities/_all";
@import "~bulma/sass/utilities/mixins.sass";

.card {
  font-family: 'Roboto', sans-serif;
}

// FIXME: justify-content: center, but short names don't work correctly so its disabled
.card-header-title {
  font-size: 20px;
  text-align: center;
  //justify-content: center;

  @include from($desktop) {
    font-size: 30px;
  }
}

.card-content{
  text-align: justify;
  font-size: 16px;

  @include from($desktop) { //desktop version
    font-size: 20px;
  }
}

.card-list {
  font-size: 14px;

  @include from($desktop) { //desktop version
    font-size: 18px;
  }
}

// FIXME: Center images above school names on mobile
.image {
  @include from($desktop) {
    padding-left: 1em;
    padding-top: 0.5em;
    padding-right: -0.5em;
  }
}

#iscblogo {
  padding-left: 0.5em;
  padding-top: 1em;
}

img.rounded {
  object-fit: cover;
  border-radius: 50%;
  height: 7.5em;
  width: 7.5em;
}
</style>
