<template>
  <AustinBackground>
    <div id="projects">
      <div class="columns is-mobile is-centered">
        <div class="column is-three-quarters">
          <h1 class="austin-page-title"><u>My Projects</u></h1>
          <section class="section" v-for="project in projects" :key="project.title">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="columns" id="title">
                    <div class="column is-narrow" v-if="project.thumbnail !== null">
                      <div class="media-left">
                        <figure class="image is-96x96">
                          <img :src="require('../assets/' + project.thumbnail)" :alt="project.title">
                        </figure>
                      </div>
                    </div>
                    <div class="column">
                      <p class="title cardtitle">
                        <VueMarkdown> {{ project.title }}</VueMarkdown>
                      </p>
                      <p class="subtitle is-5"> {{ project.date }} </p>
                    </div>
                  </div>
                </div>
                <div class="content">
                  <VueMarkdown> {{ project.content }}</VueMarkdown>
                  <div class="tag-br" v-if="project.tags !== null && project.tags.length > 0">
                    <br>
                    <a v-for="tag in project.tags" :key="tag"> #{{ tag }} </a>
                  </div>
                </div>
              </div>
              <footer class="card-footer"
                      v-if="project.github !== null || project.link !== null || project.paper !== null || project.discord !== null">
                <a :href="project.github" class="card-footer-item" v-if="project.github !== null">
                  <b-icon icon="github" size="is-medium"></b-icon>
                  Github Repository
                </a>
                <a :href="project.link" class="card-footer-item" v-if="project.link !== null">
                  <b-icon icon="link-variant" size="is-medium"></b-icon>
                  Project Link
                </a>
                <a :href="project.paper" class="card-footer-item" v-if="project.paper !== null">
                  <b-icon icon="file" size="is-medium"></b-icon>
                  Article
                </a>
                <a :href="project.discord" class="card-footer-item" v-if="project.discord !== null">
                  <b-icon icon="discord" size="is-medium"></b-icon>
                  Discord Server
                </a>
              </footer>
            </div>
          </section>
        </div>
      </div>
    </div>
  </AustinBackground>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import AustinBackground from "./AustinBackground";

export default {
  name: "AustinProjects",
  metaInfo: {
    title: "My Projects"
  },
  components: {
    AustinBackground,
    VueMarkdown
  },
  data() {
    return {
      projects: require("../assets/projects.json")
    }
  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); /*https://fonts.google.com/specimen/Roboto?sidebar.open&selection.family=Roboto#standard-styles*/

@import "../common.scss";

.cardtitle {
  text-align: center;
}

.card {
  font-family: 'Roboto', sans-serif;
}

.content {
  text-align: justify;
  font-size: 20px;
}

.card-footer {
  font-size: 20px;
}

.tag-br {
  margin-bottom: -0.5em;
}
</style>
