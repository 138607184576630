const SHAPE_DENSITY_FACTOR = 1/35000;
const COLORS = [
    "#FF99C8",
    "#FCF6BD",
    "#D0F4DE",
    "#A9DEF9",
    "#E4C1F9"
]
const SHAPES = [
    "square",
    "circle",
    "triangle",
    "squiggle"
]
const UNIT_TRIANGLE = [
    [-0.866, -0.5],
    [0.866, -0.5],
    [0.0, 1.0]
]
const MIN_DISTANCE = 0.1


export class Shape {
    constructor(id, shapeType, filled, rotation, color, scale, x, y) {
        this.id = id
        this.shapeType = shapeType
        this.filled = (shapeType === "squiggle" ? false : filled)
        this.rotation = rotation
        this.color = color
        this.scale = scale
        this.x = x
        this.y = y
    }

    polygonPoints() {
        if (this.shapeType === "square" || this.shapeType === "circle") {
            throw new Error("Invalid shape for polygon points!")
        }
        // FIXME: Triangle is rendered upside down below the actual viewport
        let centerY = this.scale*2
        let centerX = this.scale*2
        switch (this.shapeType) {
            case "triangle":
                var path = ""
                var r = this.scale / 2  // radius
                for (var point in UNIT_TRIANGLE) {
                    path += ((UNIT_TRIANGLE[point][0] * r) + centerX).toString()
                    path += ","
                    path += ((UNIT_TRIANGLE[point][1] * r) + centerY).toString()
                    path += " "
                }
                return path
            case "squiggle":
                var startX = centerX / 2
                var endX = 3 * centerX / 2
                var squiggleR = this.scale / 2

                var squgglePath = "M " + startX.toString() + "," + centerY.toString()

                squgglePath += " C " + (startX + squiggleR / 4).toString() + "," + (centerY - squiggleR).toString()
                squgglePath += " " + (centerX - squiggleR / 4).toString() + "," + (centerY - squiggleR).toString()
                squgglePath += " " + centerX.toString() + "," + centerY.toString()

                squgglePath += " C " + (centerX + squiggleR / 4).toString() + "," + (centerY + squiggleR).toString()
                squgglePath += " " + (endX - squiggleR / 4).toString() + "," + (centerY + squiggleR).toString()
                squgglePath += " " + endX.toString() + "," + centerY.toString()

                return squgglePath
        }
    }
}

export function makeRandomShape(id, x, y, scale) {
    return new Shape(
        id,
        SHAPES[randomIntInRange(0, SHAPES.length-1)],
        randomIntInRange(0, 1) === 1,
        randomFloatInRange(0, 360),
        COLORS[randomIntInRange(0, COLORS.length-1)],
        scale,
        x,
        y
    )
}

function inCoordList(coordList, maxIndex, coord) {
    for (var i = 0; i < maxIndex; i++) {
        let coordTest = coordList[i]
        if (Math.sqrt(((coordTest[0] - coord[0])**2) + ((coordTest[1] - coord[1])**2)) < MIN_DISTANCE) {
            return true
        }
    }
    return false
}

export function isMobileDensity(screenArea) {
    return screenArea <= 600*800
}

export function makeRandomShapeArray(screenArea) {
    const isMobile = isMobileDensity(screenArea)
    const density = isMobile ? SHAPE_DENSITY_FACTOR / 2 : SHAPE_DENSITY_FACTOR
    const shapeCount = Math.floor(density * screenArea)
    let allCoords = Array(shapeCount)
    let shapes = Array(shapeCount)
    for (var i = 0; i < shapeCount; i++) {
        let x = randomFloatInRange(0, 1)
        let y = randomFloatInRange(0, 1)
        while (inCoordList(allCoords, i, [x, y])) {
            x = randomFloatInRange(0, 1)
            y = randomFloatInRange(0, 1)
        }
        allCoords[i] = [x, y]
        let scale = randomFloatInRange(10, isMobile ? 40 : 85)
        shapes[i] = makeRandomShape(i, x, y, scale)
    }
    return shapes
}

function randomFloatInRange(min, max) {
    return Math.random() * (max - min) + min;
}

function randomIntInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
