<template>
  <AustinBackground>
    <div id="home">
      <div class="columns is-vcentered is-centered">
        <div class="column is-narrow">
          <img src="../assets/av_profile_pic.jpeg" alt="Profile Picture" class="rounded">
        </div>
        <div class="column is-narrow austin-page-title">
          <h1 class="title" id="name">Austin Varela</h1>
          <h3 class="subtitle" id="job">Computational Biologist & Programmer</h3>
        </div>
      </div>
      <div class="columns is-mobile is-centered">
        <div class="column is-three-quarters">
          <section class="section">
            <div id="slideshow" v-show="showCarousel">
              <b-carousel>
                <b-carousel-item v-for="(carousel, i) in carousels" :key="i">
                  <section class="hero is-medium is-vcentered">
                    <figure class="image is-3by1">
                      <img :src="carousel.img" :alt="carousel.alt">
                    </figure>
                    <div class="hero-body has-text-centered is-overlay is-vcentered" id="carouseltextdiv">
                      <h1 class="title" id="carouseltext">{{ carousel.text }}</h1>
                    </div>
                  </section>
                </b-carousel-item>
              </b-carousel>
            </div>
          </section>
          <section class="section" id="aboutcard">
            <div id="content">
              <div class="card">
                <div class="card-header">
                  <p class="card-header-title"> Hello! </p>
                  <a class="card-header-icon">
                    <b-icon icon="information-outline" size="is-medium"></b-icon>
                  </a>
                </div>
                <div class="card-content" id="aboutcardcontent">
                  I am a Computational Biology researcher with experience in a variety of
                  topics in the fields of Bioinformatics, Data Science, and Software Engineering. For
                  more information about me, please read my
                  <router-link :to="{ path: '/about' }">About page</router-link>
                  or view my projects on the
                  <router-link :to="{ path: '/projects' }">Projects page</router-link>
                  . I also have a passion
                  for education, particularly on topics relating to programming, so I occasionally post
                  on my
                  <router-link :to="{ path: '/blog' }">Blog page</router-link>
                  about various topics
                  in my fields of expertise.
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </AustinBackground>
</template>

<script>
import AustinBackground from "./AustinBackground";

export default {
  name: "AustinHome",
  components: {AustinBackground},
  data: function () {
    return {
      showCarousel: false
    }
  },
  mounted: function () {
    this.showCarousel = true
  },
  computed: {
    carousels() {
      return [
        {text: 'Evolutionary Biology', img: require('../assets/erc.png'), alt: 'Phylogenetics'}, // My ERC figure
        {text: 'Genetics', img: require('../assets/alignment.png'), alt: 'Sequences'},
        {text: 'Data Science', img: require('../assets/tsne.png'), alt: 'Data'},
        {text: 'Programming', img: require('../assets/code.png'), alt: 'Code'},
        {text: 'Network Analysis', img: require('../assets/network.png'), alt: 'Network'}
      ]
    }
  },
  metaInfo: {
    title: "Home",
    meta: [
      {
        property: 'og:url',
        content: 'https://austinv11.com'
      },
      {
        property: 'og:description',
        content: "The homepage for Austin's website."
      },
    ]
  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
/*https://fonts.google.com/specimen/Bree+Serif?preview.text=Austin+Varela&preview.text_type=custom&sidebar.open&selection.family=Bree+Serif#standard-styles*/
@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Caption&display=swap');
/*https://fonts.google.com/specimen/PT+Sans+Caption?preview.text=Computational+Biologist+programmer&preview.text_type=custom&sidebar.open&selection.family=PT+Sans+Caption#standard-styles*/
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); /*https://fonts.google.com/specimen/Roboto?sidebar.open&selection.family=Roboto#standard-styles*/

@import "../common.scss";

#aboutcardcontent {
  text-align: justify;
  font-size: 20px;
}

#aboutcard {
  padding-top: -1em;
}

#carouseltextdiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

#carouseltext {
  text-shadow: 0 0 6px white, 0 0 6px white, 0 0 6px white, 0 0 6px white;
  padding-bottom: 0.5em
}

#name {
  font-family: 'Bree Serif', serif;
  font-size: inherit;
}

#job {
  font-family: 'PT Sans Caption', sans-serif;
  color: #313335;
}

#content {
  font-family: 'Roboto', sans-serif;
}

.card-header-title {
  font-size: 20px;
}

img.rounded {
  object-fit: cover;
  border-radius: 50%;
  height: 10em;
  width: 10em;
}
</style>
