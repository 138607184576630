<template>
  <div>
    <b-modal :active.sync="isShowingInfo" scroll="keep" :width="720">
      <div class="card">
        <div class="card-content has-text-left" id="adinfo">
          <b>Why the ads?</b>
          <br>
          Generally I dislike web ads and honestly if you use adblock on my site I wouldn't blame you!
          However, in order for me to be able to properly support this site in the early stage of my
          career, I need to run ads to offset server hosting costs. I am certainly not making enough on
          ads to make a profit. I hope you understand!
        </div>
      </div>
    </b-modal>

    <slot></slot>

    <div class="has-text-weight-light	has-text-left	is-size-7">
      <a href="" @click.prevent="isShowingInfo = true">Why the ads?</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "AustinAd",
  data() {
    return {
      isShowingInfo: false,
    }
  }
}
</script>

<style scoped>

</style>
